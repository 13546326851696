import React, { useEffect, useState } from 'react'
import Layout from '../../Components/Layout'
import Banner from '../../Components/Common/Banner'
import { Packages } from '../../Data'
import Svgs from '../../Svgs'
import Form from '../../Components/Form'
import usePricingHelper from '../../Actions/Pricing'
import CheckoutForm from './StripeForm'
import { Elements, PaymentElement } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import PricingCard from '../../Components/Common/PricingCard'
import FlexRow from '../../Components/Layout/FlexRow'
import { plans } from '../../Actions/Constants'
import { useLocation } from 'react-router-dom'

const Pricing = () => {
    const { getPlans, handleSubmit, Form_ } = usePricingHelper()
    const [SelectedPackage, setSelectedPackage] = useState('');
    const [Loading, setLoading] = useState(false);
    const [Data, setData] = useState({});

    useEffect(() => {
        getPlans(setLoading, setData);
    }, []);

    const { state } = useLocation();


    useEffect(() => {
        if (state) {
            setSelectedPackage(state);
            handleSubmit();
        }
    }, [state])

    const [stripePromise, setstripePromise] = useState('')

    useEffect(() => {
        if (Form_) {
            setstripePromise(loadStripe(`pk_test_51OCzoQD01DMPQ8ozr6G1NGyOxj9C8MN80DLmjhbBYsVSsyneZVhDw95QPUzZF8Spks2Q7Z41NCSYYuRnuATVmKAm00OI3gDaUP`))
        }
    }, [Form_])


    return (
        <Layout>
            <Banner
                img={'/Assets/Images/lines.png'}
                buttons={false}
                fullscreen={false}
                data={
                    <>
                        <h2 className='font-extrabold md:text-[3rem] text-[2.2rem] leading-[1.2]'>
                            Pricing
                        </h2>
                        <p className='text-[#4F4D52] text-sm'>
                            Discover our flexible pricing options, tailored to fit your needs.
                            <br className='md:block hidden' />
                            Get a clear understanding of what you pay for.
                        </p>
                    </>
                }
            />
            <section>
                <div className='container'>

                    {SelectedPackage ?
                        <div className='lg:grid flex flex-col grid-cols-3 gap-[4rem] mb-[4rem]'>

                            <div className='flex flex-col gap-4'>
                                {
                                    !Loading ?
                                        Object.keys(Data).length > 0 && Data.data.plans.map((item, i) => {
                                            return <div onClick={() => {
                                                setSelectedPackage(item)
                                            }} className={`cursor-pointer transition-all border rounded-2xl flex items-center justify-between gap-4 py-5 px-6 border-[#1D1B21] ${item.id === SelectedPackage?.id ? "bg-[#FACA7F] text-black" : ""}`}>
                                                <div className='flex items-center gap-3'>
                                                    <input type="radio" className='w-4 h-4 text-[#FACA7F] bg-gray-100 border-gray-300 border-2' name="pricing" checked={item.id === SelectedPackage?.id} />
                                                    <p className='text-sm font-semibold capitalize'>{item.duration} {item.duration_type}</p>
                                                    {
                                                        item.discount && <p className='bg-[#131117] text-[#14C096] rounded-md text-xs px-2 py-1'>{item.discount}</p>
                                                    }
                                                </div>
                                                <div>
                                                    <p className='font-bold text-lg'><span className='uppercase'>{Data.data.currency_symbol}</span> {item.price}</p>
                                                </div>
                                            </div>
                                        }) : <>
                                            {[0, 1, 1, 1, 1].map(item => (
                                                <div className='h-[4rem] rounded-2xl w-full bg-gray-800 animate-pulse'></div>
                                            ))}
                                        </>
                                }
                            </div>
                            <div className='col-span-2'>
                                <div className=''>
                                    {
                                        Form_ && stripePromise ? <>
                                            <Elements stripe={stripePromise}>
                                                <CheckoutForm selectedPackage={SelectedPackage} cancelPurchase={() => { setSelectedPackage(null) }} />
                                            </Elements>
                                        </> : <>
                                            <div className='flex flex-col gap-8'>
                                                <h1 className='text-[1.5rem]'>Features</h1>
                                                <div className='flex flex-col gap-5'>


                                                    {
                                                        !Loading ?
                                                            Object.keys(Data).length > 0 && SelectedPackage?.features?.map((item, i) => {
                                                                return <div className='flex items-center justify-between gap-3'>
                                                                    <h1 className='text-[#FACA7F]'>{item}</h1>
                                                                    <div>
                                                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M11 21C16.525 21 21 16.525 21 11C21 5.475 16.525 1 11 1C5.475 1 1 5.475 1 11C1 16.525 5.475 21 11 21Z" stroke="#FACA7F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                            <path d="M15.5832 8.5L9.47477 14.3333L6.4165 11.4167" stroke="#FACA7F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                            }) : <>
                                                                {[0, 1, 1, 1].map(item => (
                                                                    <div className='flex items-center justify-center gap-4'>
                                                                        <div className='h-[2rem] rounded-md bg-gray-800 animate-pulse flex-1'></div>
                                                                        <div className='h-[2rem] w-[2rem] rounded-md bg-gray-800 animate-pulse'></div>
                                                                    </div>
                                                                ))}
                                                            </>
                                                    }
                                                </div>
                                                <div className='mt-[3rem] flex flex-col gap-6'>
                                                    <h1>Accepted payment methods</h1>
                                                    <div className='flex items-center gap-4'>
                                                        <Svgs.MasterCard />
                                                        <Svgs.Visa />
                                                        <Svgs.ApplePay />
                                                        <Svgs.Stripe />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='flex items-center justify-between gap-3 pt-5 flex-wrap'>
                                                <div className='flex items-center gap-2'>
                                                    <Svgs.Check />
                                                    <h1 className='text-sm text-[#14C096]'>7-days money-back guarantee</h1>
                                                </div>
                                                <div>
                                                    <Form.Button onClick={handleSubmit} text={'Choose Plan'} />
                                                </div>
                                            </div>
                                        </>
                                    }


                                </div>
                            </div>
                        </div> : <FlexRow className=" text-center justify-center  flex-col md:flex-row ">
                            {
                                !Loading ?
                                    Object.keys(Data).length > 0 && Data.data.plans.map((item, index) => {
                                        return <PricingCard
                                            name={item.name}
                                            currency={Data.data.currency_symbol}
                                            features={item.features}
                                            price={item.price}
                                            key={index}
                                            plan={item}
                                            btnTitle="Choose Plan"
                                            main={(index + 1) > 1 && (index + 1) % 2 === 0}
                                            onCardClick={(p) => {
                                                setSelectedPackage(p);
                                                handleSubmit()
                                            }}
                                        />
                                    }) : <>
                                        {[0, 1, 1, 1, 1].map(item => (
                                            <div className='h-[4rem] rounded-2xl w-full bg-gray-800 animate-pulse'></div>
                                        ))}
                                    </>
                            }
                        </FlexRow>
                    }
                </div>

            </section>
        </Layout>
    )
}

export default Pricing