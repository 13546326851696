import React from 'react'
import Layout from '../../Components/Layout'

const ShippingPolicy = () => {
    return (
        <Layout>
            <div className='my-[5rem]'>
                <div className='container'>
                    <div className='lg:grid flex flex-col grid-cols-5 gap-5 mb-[3rem]'>
                        <div className='col-span-2'>
                            <div className='sticky top-[2rem]'>
                                <h1 className='text-[2rem] font-semibold'>Shipping Policy</h1>
                                <p className='text-[#4F4D52]'>Last updated: 10 February 2023</p>
                            </div>
                        </div>
                        <div className='col-span-3'>
                            <div className='flex flex-col gap-6 text-sm'>
                                <div className='flex flex-col gap-2'>
                                    <h1 className='text-[#FACA7F] text-base'>Shipping Locations</h1>
                                    <p>OCTO VAULT ships to most countries worldwide. If you are unsure if we ship to your location, please contact us at support@octovault.org before placing your order.</p>
                                </div>


                                <div className='flex flex-col gap-2'>
                                    <h1 className='text-[#FACA7F] text-base'>Shipping Rates</h1>
                                    <p>Rates will vary depending on the size and weight of your order, as well as the shipping destination. Shipping rates will be calculated and displayed at checkout before you complete your purchase.</p>
                                </div>


                                <div className='flex flex-col gap-2'>
                                    <h1 className='text-[#FACA7F] text-base'>Processing Time</h1>
                                    <p>All orders will be processed within 1-2 business days of receipt. Once your order has been processed, you will receive a shipping confirmation email with tracking information.</p>
                                </div>


                                <div className='flex flex-col gap-2'>
                                    <h1 className='text-[#FACA7F] text-base'>Delivery Time</h1>
                                    <p>Delivery times will vary depending on the shipping destination. We use multiple shipping carriers, which have different delivery time frames. Please refer to the tracking information provided in your shipping confirmation email for an estimated delivery date.</p>
                                </div>


                                <div className='flex flex-col gap-2'>
                                    <h1 className='text-[#FACA7F] text-base'>International Taxes and Duties</h1>
                                    <p>Customers are responsible for paying any international taxes or duties that may be incurred on their order. OCTO VAULT is not responsible for any additional charges that may be incurred on international orders.</p>
                                </div>


                                <div className='flex flex-col gap-2'>
                                    <h1 className='text-[#FACA7F] text-base'>Damaged or Lost Packages</h1>
                                    <p>If you receive a damaged package or your package is lost in transit, please contact us immediately at support@octovault.org We will work with the shipping carrier to resolve the issue and either resend your package or issue a refund.</p>
                                </div>


                                <div className='flex flex-col gap-2'>
                                    <h1 className='text-[#FACA7F] text-base'>Returns and Exchanges</h1>
                                    <p>Please refer to our Return Policy for information on returns and exchanges.</p>
                                </div>


                                <div className='flex flex-col gap-2'>
                                    <h1 className='text-[#FACA7F] text-base'>Contact Us</h1>
                                    <p>If you have any questions or concerns about shipping, please contact us at support@octovault.org.</p>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default ShippingPolicy