import React from 'react'
import FlexCol from '../../Components/Layout/FlexCol'
import { OPTData } from '../../Data'
import OptValue from '../../Components/Cards/OptValue'

const WhyOpt = () => {
    return (
        <div className='py-[4rem]'>
            <FlexCol className={'gap-[2rem] justify-center'}>
                <h2 className='font-extrabold text-[3.5rem] leading-[1.2] text-center'>
                    Why Choose <span className='text-[#FACA7F]'>Octo Shield VPN?</span>
                </h2>
                <div className='mt-[3rem]'>
                    <div className='grid xl:grid-cols-3 md:grid-cols-2 gap-[2rem]'>
                        {
                            OPTData.map((item,i) => {
                                return <OptValue key={i} info={item.info} img={item.icon} title={item.title} />
                            })
                        }
                    </div>
                </div>
            </FlexCol>
        </div>
    )
}

export default WhyOpt