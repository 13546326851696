import React, { useState } from 'react'
import Layout from '../../Components/Layout'
import Banner from '../../Components/Common/Banner'
import { Packages } from '../../Data'
import Svgs from '../../Svgs'
import Form from '../../Components/Form'
const FAQS = () => {
    const [Open, setOpen] = useState(0);
    return (
        <Layout>
            <Banner
                img={'/Assets/Images/lines.png'}
                data={
                    <>
                        <h2 className='font-extrabold md:text-[3rem] text-[2.2rem] leading-[1.2]'>
                            Support Centre
                        </h2>
                        <p className='text-[#4F4D52] text-sm'>
                            Advice and Answers
                            <br className='md:block hidden' />
                            Find your answers through the frequent questions collected from our customers.
                        </p>
                    </>
                }
            />
            <section>
                <div className='container'>
                    <div className='lg:grid flex flex-col grid-cols-5 gap-5 mb-[3rem]'>
                        <div className='col-span-2'>
                            <h1 className='text-[2rem] font-semibold'>General</h1>
                        </div>
                        <div className='col-span-3'>
                            <div className='flex flex-col'>
                                {
                                    [1, 2, 2, 2, 2].map((item, i) => {
                                        return <div key={i} onClick={() => setOpen(i)} className='rounded-xl px-5 py-2.5'>
                                            <div className='flex items-center gap-4 justify-between cursor-pointer border-t border-[#1D1B21] pt-3'>
                                                <div className='flex items-center gap-3'>
                                                    Lorem ipsum dolor sit amet, usu consul?
                                                </div>
                                                <div className='flex items-center text-sm gap-3'>
                                                    <Svgs.Down />
                                                </div>
                                            </div>
                                            {
                                                Open === i && <div className='mt-3'>
                                                    <h1 className='text-[#4F4D52]'>Lorem ipsum dolor sit amet.</h1>
                                                    <p className='text-[#4F4D52] text-sm'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Omnis cupiditate magni in perspiciatis quam eaque, esse perferendis doloremque quos enim amet quibusdam quidem animi asperiores nesciunt? Cum odit quas obcaecati saepe quibusdam, commodi ut eius non. Corporis aut quod optio ratione nihil natus et, adipisci voluptas. Placeat, in. Tenetur eos natus ullam saepe nisi commodi laboriosam impedit voluptatibus dolor quae, quasi eum, dolorem quam velit iste corrupti maiores beatae provident soluta alias aliquid ad deserunt! Voluptatibus ipsum nesciunt quod magni numquam minus voluptas quas ipsam harum eveniet accusantium cumque maxime officiis, repudiandae mollitia nobis. Ratione porro nobis nam delectus consequuntur.</p>
                                                </div>
                                            }
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default FAQS