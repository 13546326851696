import { toast } from "react-toastify";
import { baseUrl, plans } from "../Constants";
import { getToken } from "../Cookies";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

function usePricingHelper() {

    const navigate = useNavigate();
    const [Form_, setForm] = useState(false)

    const getPlans = (setLoading, setData) => {
        setLoading(true)
        var requestOptions = {
            headers: {
                Accept: "application/json",
            },
        };
        fetch(baseUrl + plans, requestOptions)
            .then(resp => resp.json())
            .then(data => {
                if (data.status) {
                    setData(data)
                    setLoading(false)
                }
            })
    }

    const handleSubmit = () => {
        let token = getToken();
        if (token) {
            setForm(true)
        } else {
            toast.error('Please login to continue!');
            navigate('/login')
        }
    }

    return {
        getPlans,
        handleSubmit,
        Form_
    }
}

export default usePricingHelper