import React from 'react'
import FlexRow from '../Layout/FlexRow'
import Svgs from '../../Svgs'
import FlexCol from '../Layout/FlexCol'
import { FooterData } from '../../Data'
import { useNavigate } from 'react-router-dom'

const Footer = () => {
    const navigate = useNavigate();
    return (
        <footer>
            <div className='md:grid flex flex-col items-center md:text-left text-center grid-cols-5 gap-5 container py-[2rem]'>
                <FlexCol className={'gap-[2rem] col-span-2'}>
                    <div>
                        {/* <Svgs.Logo /> */}
                        <img src={`${process.env.PUBLIC_URL}/Assets/Images/octo-shield-white.png`} className='h-[3rem]'  alt='footer img'/>
                    </div>
                    <FlexRow>
                        <div className='border border-[#1d1b21] rounded-lg p-2 cursor-pointer'>
                            <Svgs.Facebook />
                        </div>
                        <div className='border border-[#1d1b21] rounded-lg p-2 cursor-pointer'>
                            <Svgs.Instagram />
                        </div>
                        <div className='border border-[#1d1b21] rounded-lg p-2 cursor-pointer'>
                            <Svgs.Linkedin />
                        </div>
                        <div className='border border-[#1d1b21] rounded-lg p-2 cursor-pointer'>
                            <Svgs.Twitter />
                        </div>
                    </FlexRow>
                </FlexCol>
                {
                    FooterData.map((item,i) => {
                        return <FlexCol key={i} className={'gap-[2rem]'}>
                            <h1>{item.title}</h1>
                            <FlexCol className={'text-[#4F4D52]'}>
                                {
                                    item.list.map((li,index) => <h1 key={index} onClick={() => navigate(li.url)} className='cursor-pointer hover:text-[#fff] w-fit transition-all'>{li.name}</h1>)
                                }
                            </FlexCol>
                        </FlexCol>
                    })
                }
            </div>
            <div className='border-t border-[#1D1B21]'>
                <div className='container py-3'>
                    <FlexRow className='md:justify-between justify-center gap-4 flex-wrap'>
                        <div>
                            <h2 className='text-[#4F4D52] text-sm font-semibold md:text-left'>2023 © Octo Shield Vpn. ALL RIGHTS RESERVED.</h2>
                        </div>
                        <FlexRow className={'gap-4'}>
                            <div><Svgs.MasterCard /></div>
                            <div><Svgs.Visa /></div>
                            <div><Svgs.ApplePay /></div>
                            <div><Svgs.Stripe /></div>
                        </FlexRow>
                    </FlexRow>
                </div>
            </div>
        </footer>
    )
}

export default Footer