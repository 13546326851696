import React, { useEffect, useState } from 'react'
import PricingCard from '../../Components/Common/PricingCard'
import FlexRow from '../../Components/Layout/FlexRow'

import { baseUrl, plans } from '../../Actions/Constants'

const Pricing = () => {



    const [Loading, setLoading] = useState(false);
    const [Data, setData] = useState({});

    useEffect(() => {
        setLoading(true)
        var requestOptions = {
            headers: {
                Accept: "application/json",
            },
        };
        fetch(baseUrl + plans, requestOptions)
            .then(resp => resp.json())
            .then(data => {
                if (data.status) {
                    setData(data)
                    setLoading(false)
                }
            })
    }, []);




    return (

        <div data-aos="zoom-in ">
            <h2 className=" text-center  font-extrabold md:text-[3rem] text-[2.2rem] leading-[1.2] mb-8">
                Pricing
            </h2>
            <FlexRow className=" text-center justify-center flex-col md:flex-row gap-4 md:gap-0" gap={0}>
                {!Loading ? Object.keys(Data).length > 0 && Data.data.plans.map((item, index) => {
                    return <PricingCard
                        name={item.name}
                        currency={Data.data.currency_symbol}
                        features={item.features}
                        price={item.price}
                        key={index}
                        plan={item}
                        main={(index + 1) > 1 && (index + 1) % 2 === 0}
                    />
                }) : <>

                </>
                }
            </FlexRow>
        </div>

    )
}

export default Pricing