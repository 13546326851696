import React from 'react'
import HeaderButtons from './HeaderButton'

const Banner = ({ data, img, buttons = true, fullscreen = true }) => {
    return (
        <div className={`grid ${fullscreen && 'h-screen'} md:grid-cols-2 gap-[2rem]`}>
            <div className='flex  items-center justify-center' data-aos="fade-left">
                <img src={`${process.env.PUBLIC_URL}${img}`} className="object-contain h-[30rem] " alt='alt' />
            </div>
            <div className={'flex flex-col gap-[2rem] justify-center'} data-aos="fade-right">
                {data}
                {
                    buttons &&
                    <HeaderButtons></HeaderButtons>
                }
            </div>
        </div>
    )
}

export default Banner