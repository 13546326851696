import React from 'react'
import FlexCol from '../Layout/FlexCol'
import Form from '../Form'

const OptValue = ({ img, title, info }) => {
    return (
        <div className=' px-6 py-10'>
            <FlexCol className={'gap-4 items-center'}>
                <div>
                    <Form.Button className={'!px-2.5 !py-2.5'}>
                        <img alt='alt' src={`${process.env.PUBLIC_URL}/Assets/Images/${img}`} className="h-[3rem] object-contain" />
                    </Form.Button>
                </div>
                <div>
                    <h2 className='my-4 text-[19px] text-center  text-[#FACA7F]'>{title}</h2>
                </div>
                <div>
                    <p  className='text-lg text-center'>{info}</p>
                </div>
            </FlexCol>
        </div>
    )
}

export default OptValue