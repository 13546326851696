import React from 'react'
import FlexCol from '../../Components/Layout/FlexCol'
import Form from '../../Components/Form'
import FlexRow from '../../Components/Layout/FlexRow'
import Svgs from '../../Svgs'

const Privacy = () => {
    return (
        <div className='p-[2px] mt-[6rem] border-gradient rounded-3xl' data-aos="fade-left">
            <div className='rounded-3xl px-[3rem] py-[2rem] bg-[#06030e]'>
                <div className='grid lg:grid-cols-2 gap-[2rem]'>
                    <FlexCol className={'gap-[2rem] justify-center'}>
                        <div>
                            <Form.Button className={'border !py-2 border-[#FACA7F] text-white !rounded-full !bg-[#100825]'}>
                                Privacy
                            </Form.Button>
                        </div>
                        <h2 className='font-extrabold text-[1.8rem] leading-[1.2]'>
                            Protect Your Online Privacy with Trusted
                            <br className='md:block hidden' />
                            and High-Speed VPN from Octo Vault
                        </h2>
                        <p className='text-[#ddd] '>
                            {/* In today's digital age, it's common for people to want access to apps and websites that are restricted in their location, as well as to keep their personal information prote cted when shopping online or using public Wi-Fi. */}
                            <br className='md:block hidden' />
                            <br className='md:block hidden' />
                            At Octo Vault, we understand the importance of maintaining privacy and security while browsing the web. That's why we offer Octo Shield Vpn, a top-notch VPN service provider globally and one of the best in the United Arab Emirates. Our mission is to provide our users with the highest quality VPN service possible, so they can browse the web with peace of mind.
                        </p>
                        <div>
                            <Form.Button>
                                <FlexRow className='text-sm'>
                                    <h1>Order Now</h1>
                                    <Svgs.ChevronStart />
                                </FlexRow>
                            </Form.Button>
                        </div>
                    </FlexCol>
                    <div className='flex items-center justify-center'>
                        <img src={`${process.env.PUBLIC_URL}/Assets/Images/secure-img.png`} className="object-contain lg:h-[30rem] md:h-[22rem] h-[15rem]" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Privacy