import React from 'react'
import { VPN_Servers, baseUrl } from '../Constants';
import { toast } from 'react-toastify';

const getServers = async (setData, setLoading) => {
    setLoading(true)
    fetch(baseUrl + VPN_Servers)
        .then(response => response.json())
        .then(result => {
            setLoading(false)
            if (result.status) {
                setData(result?.data?.servers)
            }
        })
        .catch(error => {
            toast.error('Something went wrong!')
            console.log('error', error)
        });
}
export default getServers 
