import React from 'react'
import Layout from '../../Components/Layout'

const PrivacyPolicy = () => {
    return (
        <Layout>
            <div className='my-[5rem]'>
                <div className='container'>
                    <div className='lg:grid flex flex-col grid-cols-5 gap-5 mb-[3rem]'>
                        <div className='col-span-2'>
                            <div className='sticky top-[2rem]'>
                                <h1 className='text-[2rem] font-semibold'>Privacy Policy</h1>
                                <p className='text-[#4F4D52]'>Last updated: 10 February 2023</p>
                            </div>
                        </div>
                        <div className='col-span-3'>
                            <div className='flex flex-col gap-6 text-sm'>
                                <div className='flex flex-col gap-2'>
                                    <h1 className='text-[#FACA7F] text-base'>Privacy Policy</h1>
                                    <p>At OCTO VAULT, we are committed to protecting the privacy of our customers and website visitors. This Privacy Policy explains the types of personal information we collect, how we use it, and the steps we take to safeguard it.</p>
                                </div>


                                <div className='flex flex-col gap-2'>
                                    <h1 className='text-[#FACA7F] text-base'>Information Collection and Use</h1>
                                    <p>We collect personal information from our customers and website visitors when they purchase a VPN package or pre-configured VPN router, create an account, or subscribe to our newsletter. The information we collect may include name, email address, mailing address, phone number, and payment information.

                                        We use this information to process orders, provide customer support, send marketing and promotional communications, and improve our products and services.</p>
                                </div>


                                <div className='flex flex-col gap-2'>
                                    <h1 className='text-[#FACA7F] text-base'>Cookies and Tracking Technologies</h1>
                                    <p>We use cookies and other tracking technologies to improve the user experience on our website and to personalize content and advertisements. These technologies may collect information about your browsing activity, device type, and IP address.</p>
                                </div>


                                <div className='flex flex-col gap-2'>
                                    <h1 className='text-[#FACA7F] text-base'>Sharing of Information</h1>
                                    <p>We do not sell or rent personal information to third parties. We may share information with third parties for the purpose of providing our services, such as processing payments, handling customer support, and sending marketing and promotional communications.</p>
                                </div>


                                <div className='flex flex-col gap-2'>
                                    <h1 className='text-[#FACA7F] text-base'>Data Retention</h1>
                                    <p>We take reasonable measures to protect personal information from loss, misuse, unauthorized access, disclosure, alteration, and destruction.</p>
                                </div>


                                <div className='flex flex-col gap-2'>
                                    <h1 className='text-[#FACA7F] text-base'>Security</h1>
                                    <p>We take reasonable measures to protect personal information from loss, misuse, unauthorized access, disclosure, alteration, and destruction.</p>
                                </div>


                                <div className='flex flex-col gap-2'>
                                    <h1 className='text-[#FACA7F] text-base'>Third-Party Links</h1>
                                    <p>Our website may contain links to third-party websites. We are not responsible for the privacy practices of these websites.</p>
                                </div>


                                <div className='flex flex-col gap-2'>
                                    <h1 className='text-[#FACA7F] text-base'>Changes to this Privacy Policy</h1>
                                    <p>We reserve the right to update this Privacy Policy at any time. We will notify you of any changes by posting the new Privacy Policy on our website.</p>
                                </div>


                                <div className='flex flex-col gap-2'>
                                    <h1 className='text-[#FACA7F] text-base'>Contact Us</h1>
                                    <p>If you have any questions about this Privacy Policy, please contact us at support@octovault.org.

                                    </p>
                                    <p className='text-[#4F4D52]'>By using our website and services, you consent to the collection and use of your personal information as described in this Privacy Policy.</p>
                                </div>



                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default PrivacyPolicy