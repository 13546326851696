import React, { useEffect, useState } from 'react'
import Banner from '../../Components/Common/Banner'
import Form from '../../Components/Form'
import FlexRow from '../../Components/Layout/FlexRow'
import Svgs from '../../Svgs'
import Layout from '../../Components/Layout'
import FlexCol from '../../Components/Layout/FlexCol'
import getServers from '../../Actions/Servers'

const Servers = () => {
    const [Loading, setLoading] = useState(true);
    const [Data, setData] = useState([]);
    const [FinalData, setFinalData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    useEffect(() => {
        getServers(setData, setLoading);
    }, []);

    useEffect(() => {
        setFinalData(Data)
    }, [Data])

    const handleSearch = (e) => {
        const term = e.target.value.toLowerCase();
        setSearchTerm(term);
        const filteredData = Data.filter(item =>
            item.country.toLowerCase().includes(term)
        );
        setFinalData(term ? filteredData : Data);
    };

    return (
        <Layout>
            <Banner
                img={'/Assets/Images/map.png'}
                data={
                    <>
                        <h2 className='font-extrabold md:text-[3rem] text-[2.2rem] leading-[1.2]'>
                            Choose Your Ideal
                            <br className='md:block hidden' />
                            Server Location
                        </h2>
                        <p className='text-[#fff] text-sm'>
                            At Octo Shield Vpn, we understand the importance of speed, which is
                            <br className='md:block hidden' />
                            why we've invested in over 100 servers across 45+ countries to deliver
                            <br className='md:block hidden' />
                            lightning-fast connections to our customers.
                            <br className='md:block hidden' />
                            <br className='md:block hidden' />
                            Our servers utilize the latest encryption and security protocols, providing
                            <br className='md:block hidden' />
                            our customers with the ultimate protection for their online activities.
                            <br className='md:block hidden' />
                        </p>
                        <div>
                            <Form.Button>
                                <FlexRow className='text-sm'>
                                    <h1 className='font-semibold'>Get Started</h1>
                                    <Svgs.ChevronStart />
                                </FlexRow>
                            </Form.Button>
                        </div>
                    </>
                }
            />
            <section>
                <div className='mt-[3rem] mb-[5rem]'>
                    <FlexCol>
                        <h1 className='text-center text-[1.5rem]'>Find your server</h1>
                        <p className='text-center text-sm'>Search your preferred server location and determine its availability for both free and premium users.</p>
                    </FlexCol>
                    <div className='flex flex-col gap-[3rem] my-[3rem]'>
                        <div>
                            <div className='border border-[#1D1B21] rounded-xl px-5 py-3 flex items-center gap-3'>
                                <Svgs.Search />
                                <input type="text" onChange={handleSearch} className='w-full border-none outline-none bg-transparent text-sm' placeholder='Search here...' />
                            </div>
                        </div>
                        <div>

                            <div className='overflow-auto'>
                                <div className="relative shadow-md sm:rounded-lg min-w-full w-fit">
                                    <table className="w-full text-sm text-left text-gray-500 border border-[#141216] sm:rounded-lg">
                                        <thead className="text-xs text-[#4F4D52] bg-[#141216]">
                                            <tr>
                                                <th scope="col" className="px-6 py-3">
                                                    Location
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    Free
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    Open VPN
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    Any Connect
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    SSH
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    Shadow Sock
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                Loading ? [1, 2, 3, 4].map(item => <tr>
                                                    <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap text-white border-r border-[#141216]">
                                                        <div className='flex itemc gap-2'>
                                                            <div className='bg-gray-800 animate-pulse rounded-md h-[1.2rem] w-[3.2rem]'></div>
                                                        </div>
                                                    </th>
                                                    <td className="px-6 py-4 border-r border-[#141216]">
                                                        <div className='bg-gray-800 animate-pulse rounded-md h-[1.2rem] w-[3.2rem]'></div>
                                                    </td>
                                                    <td className="px-6 py-4 border-r border-[#141216]">
                                                        <div className='bg-gray-800 animate-pulse rounded-md h-[1.2rem] w-[3.2rem]'></div>
                                                    </td>
                                                    <td className="px-6 py-4 border-r border-[#141216]">
                                                        <div className='bg-gray-800 animate-pulse rounded-md h-[1.2rem] w-[3.2rem]'></div>
                                                    </td>
                                                    <td className="px-6 py-4 border-r border-[#141216]">
                                                        <div className='bg-gray-800 animate-pulse rounded-md h-[1.2rem] w-[3.2rem]'></div>
                                                    </td>
                                                    <td className="px-6 py-4">
                                                        <div className='bg-gray-800 animate-pulse rounded-md h-[1.2rem] w-[3.2rem]'></div>
                                                    </td>
                                                </tr>) : FinalData.map(item => <tr>
                                                    <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap text-white border-r border-[#141216]">
                                                        <div className='flex itemc gap-2'>
                                                            <img className='h-[1.4rem] object-contain object-top' src={`https://flagsapi.com/${(item?.flag).toUpperCase()}/shiny/64.png`} />
                                                            <h1>{item?.country}</h1>
                                                            {/* <p className='text-[#4F4D52]'>New York</p> */}
                                                        </div>
                                                    </th>
                                                    <td className="px-6 py-4 border-r border-[#141216]">
                                                        {item?.free}
                                                    </td>
                                                    <td className="px-6 py-4 border-r border-[#141216]">
                                                        {item?.open_vpn}
                                                    </td>
                                                    <td className="px-6 py-4 border-r border-[#141216]">
                                                        {item?.any_connect}
                                                    </td>
                                                    <td className="px-6 py-4 border-r border-[#141216]">
                                                        {item?.ssh}
                                                    </td>
                                                    <td className="px-6 py-4">
                                                        {item?.shadow_sock}
                                                    </td>
                                                </tr>
                                                )
                                            }


                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </Layout>
    )
}

export default Servers