import React, { useState } from 'react';
import Svgs from '../../Svgs';
import { questions } from '../../Data';
import { useNavigate } from 'react-router-dom';



const FAQS = () => {
    const navigate = useNavigate();

    const [openIndex, setOpenIndex] = useState(null);

    const updateIndex = (index) => {
        if (index === openIndex) {
            setOpenIndex(null);
        } else {
            setOpenIndex(index);
        }
    };

    return (
        <>
            <section className="flex items-center text-center justify-center h-full">
                <div data-aos="zoom-in" className="container">
                    <h1 className="text-[2rem] p-10 text-center text-[#FACA7F] font-semibold">FAQ</h1>
                    <div data-aos="fade-right" className="col-span-3">
                        <div className="flex flex-col">
                            {questions.map((qa, i) => (
                                <div  key={i} className="accordion-item">
                                    <div
                                        onClick={() => updateIndex(i)}
                                        className={`rounded-xl px-5 py-4 mb-4 ${openIndex === i ? 'active' : ''
                                            }`}
                                    >
                                        <div className="flex items-center gap-4 justify-center cursor-pointer border-t border-[#1D1B21] pt-3">
                                            <div className="flex items-center gap-3 text-[#FACA7F] text-[1.5rem]">
                                                {qa.question}
                                            </div>
                                            <div className="flex items-center text-sm gap-3">
                                                {openIndex === i ? <Svgs.Up /> : <Svgs.Down />}
                                            </div>
                                        </div>
                                        {openIndex === i && (
                                            <div className="accordion-content">
                                                <p className="text-[#fff] text-lg">{qa.answer}</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>{' '}
                    <button className="bg-[#FACA7F] rounded-full px-7 py-3 text-lg text-black mt-5" onClick={() => navigate('/Price')}>
                        <h1>Order VPN</h1>
                    </button>
                </div>
            </section>
        </>
    );
};

export default FAQS;
