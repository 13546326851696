import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import Form from '../../Components/Form';
import { baseUrl } from '../../Actions/Constants';
import { getToken } from '../../Actions/Cookies';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const CheckoutForm = ({ selectedPackage, cancelPurchase }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);
    const [prev, setprev] = useState(false);
    const navigate = useNavigate();


    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            return;
        }

        setLoading(true);

        try {
            // Create a payment method using the card element
            const { paymentMethod, error } = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement(CardElement),
            });

            if (error) {
                console.error(error);
                toast.error(error?.message);
                setLoading(false);
                return;
            }

            if (!prev) {
                // Request Create Payment Intent
                var formdata = new FormData();
                formdata.append("payment_method", paymentMethod.id);
                formdata.append("plan_id", selectedPackage?.id);
                var requestOptions = {
                    method: 'POST',
                    headers: {
                        Accept: "application/json",
                        Authorization: getToken()
                    },
                    body: formdata,
                };

                const response = await fetch(baseUrl + "create-stripe-intent", requestOptions);
                const data = await response.json();

                if (!data.status) {
                    // Handle Error
                    setLoading(false);
                    return;
                } else {
                    setprev(data?.data?.intent);
                }
            } else {
                // Request Create Payment Intent
                var formdata = new FormData();
                formdata.append("payment_method", paymentMethod.id);
                formdata.append("payment_id", prev?.id);
                var requestOptions = {
                    method: 'POST',
                    headers: {
                        Accept: "application/json",
                        Authorization: getToken()
                    },
                    body: formdata,
                };

                const response = await fetch(baseUrl + "update-stripe-intent", requestOptions);
                const data = await response.json();

                if (!data.status) {
                    // Handle Error
                    setLoading(false);
                    return;
                } else {
                    setprev(data?.data?.intent);
                }
            }


            // Making this blocked scope
            {
                console.log(prev, "400000000000000240000000000000024000000000000002");
                const { paymentIntent, confirmError, error } = await stripe.confirmCardPayment(prev.client_secret, {
                    payment_method: paymentMethod.id,
                });

                if (confirmError?.message) {
                    console.log(confirmError, "========> confirmError");
                    toast.error(confirmError?.message);
                }

                if (error?.message) {
                    console.log(error, "========> error");
                    toast.error(error?.message);
                }

                if (paymentIntent?.status === "succeeded") {
                    // Notify Server About Payment Confirmation with alog payment id 
                    // Send Post request 
                    toast.info('Payment confirmed, updating credits');

                    var formdata = new FormData();
                    formdata.append("payment_id", paymentIntent?.id);
                    var requestOptions = {
                        method: 'POST',
                        headers: {
                            Accept: "application/json",
                            Authorization: getToken()
                        },
                        body: formdata,
                    };
                    const response = await fetch(baseUrl + "payment-confirmed", requestOptions);
                    const data = await response.json();
                    console.log(data, "DATA FROM CONFIRMATION");
                    if (data?.status) {
                        toast.success('Credits updated successfully!');
                        navigate('/')
                    }
                }
            }


        } catch (error) {
            console.error(error);
        }
        finally {
            setLoading(false);
        }
    };

    const cardElementOptions = {
        style: {
            base: {
                fontSize: '16px',
                color: '#fff',
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a',
            },
        },
        hidePostalCode: true,
    };

    return (
        <form onSubmit={handleSubmit} className='flex flex-col gap-4'>
            <h1 className='text-2xl'>Add Card Information</h1>
            <div className='border border-[#4F4D52] rounded-lg px-4 py-3'>
                <CardElement options={cardElementOptions} />
            </div>
            <div className='text-right '>
                {!loading &&
                    <Form.Button className={'px-12 mx-4 '} disabled={loading} onClick={cancelPurchase}>
                        Cancel
                    </Form.Button>
                }
                <Form.Button className={'px-12'} disabled={loading} type={'submit'}>
                    {loading ? 'Processing...' : 'Pay'}
                </Form.Button>
            </div>
        </form>
    );
};

export default CheckoutForm;
