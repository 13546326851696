import React from 'react'
import FlexCol from '../../Components/Layout/FlexCol'

const Global = () => {
    return (
        <>
            <div data-aos="fade-left">
                <FlexCol className={'gap-4 py-[4rem]'}>
                    <h2 className='font-extrabold text-[2.5rem] leading-[1.2]'>
                        <span>
                            Connect to High-Speed <br className='md:block hidden' /> Servers Across the Globe <br className='md:block hidden' /> with a&nbsp;
                        </span>
                        <span className='text-[#FACA7F]'>
                            Single Click
                        </span>
                    </h2>
                    <h2 className='text-[#4F4D52] text-sm'>
                        Experience the Best VPN Service with Convenient Setup and Connect to Any Location from Anywhere <br className='md:block hidden' /> with Our Fastest Servers.
                    </h2>
                </FlexCol>
            </div>
            <div data-aos="fade-right">
                <FlexCol className={'gap-[4rem]'}>
                    <div>
                        <h1 className='text-center font-bold text-[2rem]'>Enjoy Global Connectivity with
                            <br className='md:block hidden' />
                            Over <span className='text-[#FACA7F]'>100 Servers </span> in <span className='text-[#FACA7F]'> 65+ Locations </span> Worldwide
                        </h1>
                    </div>
                    <div>
                        <img src={`${process.env.PUBLIC_URL}/Assets/Images/wp-map.png`} className='lg:h-[30rem] h-[23rem] mx-auto object-contain' alt="" />
                    </div>
                </FlexCol>
            </div>
        </>
    )
}

export default Global