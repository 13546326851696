import React, { useState } from 'react'
import Popup from '../../Components/Common/Popup'
import FlexCol from '../../Components/Layout/FlexCol'
import Form from '../../Components/Form'
import useValidations from '../../Components/Form/Validations'
import { text } from '../../Components/Form/Validations/Schema'
import useTicketsHelper from '../../Actions/Tickets'

const AddTicket = ({ open, close, setChange }) => {
    const { createTicket } = useTicketsHelper();
    const [Loading, setLoading] = useState(false)
    const initialValues = { subject: "", description: "" }
    const validationSchema = { subject: text, description: text }
    const onSubmit = (values) => {
        createTicket(values, setLoading, close, form).then(res => {
            setLoading(false);
            close(s => !s);
            form.resetForm();
            setChange(s => !s);
        });
    }
    const form = useValidations({ onSubmit, validationSchema, initialValues });
    return (
        <Popup open={open} close={close} heading={'Open New ticket'}>
            <form onSubmit={form.handleSubmit}>
                <FlexCol className={'gap-4'}>
                    <Form.Input name={'subject'} form={form} placeholder={'Enter Subject'} />
                    <Form.Textarea name={'description'} form={form} placeholder={'Enter Description'} />
                    <div className='flex items-center justify-end'>
                        <Form.Button loading={Loading} type={'submit'}>
                            Submit
                        </Form.Button>
                    </div>
                </FlexCol>
            </form>
        </Popup>
    )
}

export default AddTicket