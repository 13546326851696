import React, { useEffect, useState } from 'react'
import FlexRow from '../Layout/FlexRow'
import Svgs from '../../Svgs'
import { HeaderData } from '../../Data'
import Form from '../Form'
import { useNavigate } from 'react-router-dom'
import { clearCookie, getToken } from '../../Actions/Cookies'
import useAuth from '../../Actions/Auth'
import { toast } from 'react-toastify'
import FlexCol from '../Layout/FlexCol'

const Header = () => {
  const naviagte = useNavigate();
  const [isLogin, setIsLogin] = useState(false);
  const [UserData, setUserData] = useState({});
  const [Loading, setLoading] = useState(false);

  const token = getToken();
  const { getUserData, General } = useAuth();

  useEffect(() => {
    if (token) {
      getUserData(setUserData, setIsLogin, setLoading)
    }
  }, [])

  const [isMenuOpen, setMenuOpen] = useState(false);
  const [GetApi, setGetApi] = useState(false);
  const [IsProtected, setIsProtected] = useState(false);

  useEffect(() => {
    General(setGetApi, setIsProtected);
  }, [])


  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };



  return (
    <header>
      <>
        <div className='border-b border-[#1D1B21]'>
          <div className='container py-3'>
            <FlexRow className='lg:justify-between justify-center flex-wrap-reverse'>
              <FlexRow className='gap-8 text-sm flex-wrap'>
                <div>
                  <div className='flex items-center gap-2'>
                    <h1 className='text-[#4f4d52]'>Your Ip:</h1>
                    {
                      GetApi ? GetApi : <div className='flex items-center gap-1'>
                        <div className='animate-pulse h-[1.2rem] w-[1rem] bg-white/30 rounded-sm'></div>
                        <div className='animate-pulse h-[1.2rem] w-[1rem] bg-white/30 rounded-sm'></div>
                        <div className='animate-pulse h-[1.2rem] w-[1rem] bg-white/30 rounded-sm'></div>
                        <div className='animate-pulse h-[1.2rem] w-[1rem] bg-white/30 rounded-sm'></div>
                      </div>
                    }
                  </div>
                </div>
                <div>
                  <div className='flex items-center gap-2 text-[#4F4D52]'>Status: {!GetApi && !IsProtected ? <div className='animate-pulse h-[1.2rem] w-[5rem] bg-white/30 rounded-sm'></div> : IsProtected ? <span className='text-green-500'>Protected</span> : <span className='text-[#E84E4E]'>Unprotected</span>}</div>
                </div>
              </FlexRow>
              <FlexRow className={'gap-4'}>
                <Svgs.Facebook />
                <Svgs.Instagram />
                <Svgs.Linkedin />
                <Svgs.Twitter />
              </FlexRow>
            </FlexRow>
          </div>
        </div>
      </>
      <div className='sticky top-0'>
        <div className="container pt-4 pb-6">
          <div className={'grid lg:grid-cols-3 grid-cols-2 gap-5'}>
            <div>
              {/* <Svgs.Logo /> */}
              <img onClick={() => naviagte("/")} src={`${process.env.PUBLIC_URL}/Assets/Images/octo-shield-white.png`} className='h-[3rem]' alt='logo' />
            </div>
            <FlexRow className='lg:flex hidden gap-[3rem] justify-center'>
              {
                HeaderData.map((item, i) => {
                  return <div key={i} onClick={() => {
                    naviagte(item.url)
                  }} className='cursor-pointer border-b-[2px] border-transparent transition-all hover:border-b-[#FACA7F]'>{item.name}</div>
                })
              }
            </FlexRow>
            <FlexRow className={'lg:flex hidden justify-end'}>
              {
                Loading ? <div className='flex items-center gap-2'>
                  <div className='h-[2rem] w-[2rem] rounded-md animate-pulse bg-gray-800'></div>
                  <div className='h-[2rem] w-[5rem] rounded-md animate-pulse bg-gray-800'></div>
                </div>
                  : isLogin && Object.keys(UserData).length > 0 ? <>
                    <div className='flex items-center gap-3'>
                      <div>
                        <h1 className='text-[#FACA7F]'>{UserData?.user?.name}</h1>
                      </div>
                      <div className='h-[3rem] w-[3rem] rounded-full overflow-hidden'>
                        <img src={UserData?.user?.profile_photo_url} className="h-full w-full object-contain" />
                      </div>
                      <div className='cursor-pointer' title='Logout' onClick={() => {
                        clearCookie('token')
                        setIsLogin(false);
                        toast.success('Logged out successfully!');
                      }}>
                        <Svgs.Logout />
                      </div>
                    </div>
                  </> :
                    <Form.Button onClick={() => {
                      naviagte("/login")
                    }} text={'Log In'} />
              }
            </FlexRow>

            <button onClick={toggleMenu} className="lg:hidden ml-auto" type="button">
              <Svgs.Hamburger />
            </button>
          </div>
        </div>
      </div>



      {
        isMenuOpen && <div onClick={toggleMenu} className="bg-gray-900/80 fixed inset-0 z-30"></div>
      }

      <div className={`fixed top-0 right-0 z-40 h-screen p-4 overflow-y-auto transition-transform ${!isMenuOpen && 'translate-x-full'} w-full bg-gray-800/60 backdrop-blur-md`}>
        <div className='flex flex-col gap-[3rem] h-full justify-between'>
          <div className='flex items-center justify-between'>
            {/* <Svgs.Logo /> */}
            <img src={`${process.env.PUBLIC_URL}/Assets/Images/octo-shield-white.png`} className='h-[3rem]' alt='hel' />

            <div onClick={toggleMenu}>
              <Svgs.Close />
            </div>
          </div>

          <FlexCol>
            <FlexRow className='justify-center !gap-4 flex-wrap'>
              {
                HeaderData.map((item, i) => {
                  return <div key={i} onClick={() => {
                    naviagte(item.url)
                  }} className='cursor-pointer text-2xl font-semibold'>{item.name}</div>
                })
              }
            </FlexRow>
          </FlexCol>
          <div>
            {
              Loading ? <div className='flex items-center gap-2 justify-center'>
                <div className='h-[2rem] w-[2rem] rounded-md animate-pulse bg-gray-200'></div>
                <div className='h-[2rem] w-[5rem] rounded-md animate-pulse bg-gray-200'></div>
              </div>
                : isLogin && Object.keys(UserData).length > 0 ? <>
                  <div className='flex items-center gap-3 justify-center'>
                    <div>
                      <h1 className='text-[#FACA7F]'>{UserData?.user?.name}</h1>
                    </div>
                    <div className='h-[3rem] w-[3rem] rounded-full overflow-hidden'>
                      <img src={UserData?.user?.profile_photo_url} className="h-full w-full object-contain" />
                    </div>
                    <div className='cursor-pointer' title='Logout' onClick={() => {
                      clearCookie('token')
                      setIsLogin(false);
                      toast.success('Logged out successfully!');
                    }}>
                      <Svgs.Logout />
                    </div>
                  </div>
                </> : <div className='flex items-center justify-center'>
                  <Form.Button className={'max-w-[18.75rem] w-full'} onClick={() => {
                    naviagte("/login")
                  }} text={'Log In'} />
                </div>
            }
          </div>
        </div>
      </div>

    </header>
  )
}

export default Header