import React from 'react'
import Layout from '../../Components/Layout'
import Platforms from './Platforms.jsx'
import OnlinePresence from './OnlinePresence.jsx'
import Privacy from './Privacy.jsx'
import WhyOpt from './WhyOpt.jsx'
import Global from './Global.jsx'
import Banner from '../../Components/Common/Banner'
import Pricing from './Pricing.jsx'
import FAQS from './FAQs.jsx'

const Home = () => {
    return (
        <Layout>
            <Banner
                img={'/Assets/Images/artwork.png'}
                data={
                    <>
                        <h2 className='font-extrabold md:text-[3rem] text-[2.2rem] leading-[1.2]'>
                            Shield your Online
                            <br className='md:block hidden' />
                            Activity and Security
                        </h2>
                        <p className='text-[#FACA7F] text-sm'>
                            Browse the web without being tracked and protect your personal
                            <br className='md:block hidden' />
                            information with our top-rated encryption technology.
                        </p>
                    </>
                }
            />
            <WhyOpt />
            <OnlinePresence />
            <Platforms />
            <Pricing />
            <Privacy />
            <FAQS />
            <Global />
        </Layout>
    )
}

export default Home