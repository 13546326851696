import React from 'react'
import FlexRow from '../Layout/FlexRow'
import FlexCol from '../Layout/FlexCol'
import { useNavigate } from 'react-router-dom'
import Form from '../Form'
const HeaderButtons = () => {
    const naviagte = useNavigate();
    return (
        <div data-aos="fade-left" >
            <FlexRow className='gap-4' >
                <div>
                    <Form.Button
                        className={' w-full bg-black border-white text-white'}
                        onClick={() => {
                            window.open("https://apps.apple.com/us/app/octovault-vpn/id6445982276", '_blank');
                        }} >
                        <FlexRow >
                            <img src={`${process.env.PUBLIC_URL}/Assets/Images/bi_apple.png`} alt="Chevron Start" className='h-[3rem] w-[3rem]' />
                            <FlexCol >
                                <p className='text-sm'>Download on the</p>
                                <h1 className='text-lg'> App Store</h1>
                            </FlexCol>
                        </FlexRow>
                    </Form.Button>
                </div> <div>
                    <Form.Button className={' w-full bg-black border-white text-white'}
                        onClick={() => {
                            window.open("https://play.google.com/store/apps/details?id=com.kolpolok.octo8vault&hl=en&gl=US&pli=1", '_blank');
                        }} >
                        <FlexRow className='text-sm'>
                            <img src={`${process.env.PUBLIC_URL}/Assets/Images/logos_google-play-icon.png`} alt="Chevron Start" className='h-[3rem] w-[3rem]' /> <FlexCol >
                                <p className='text-sm'>Get it on</p>
                                <h1 className='text-lg'> Google Play</h1>
                            </FlexCol>


                        </FlexRow>
                    </Form.Button>
                </div>


            </FlexRow>

            <div style={{ paddingTop: '2rem' }} >
                <Form.Button className='rounded-full' onClick={() => {
                    naviagte("/pricing")
                }} >
                    <FlexRow className='text-xl'>
                        <h1>Order VPN</h1>

                        {/* <Svgs.ChevronStart /> */}
                    </FlexRow>
                </Form.Button>
            </div>
        </div>
    )
}

export default HeaderButtons;