import React, { useEffect, useState } from 'react'
import Layout from '../../Components/Layout'
import Banner from '../../Components/Common/Banner'
import Svgs from '../../Svgs'
import Form from '../../Components/Form'
import AddTicket from './AddTicket'
import useTicketsHelper from '../../Actions/Tickets'
import DetailsPopup from './DetailsPopup'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { getToken } from '../../Actions/Cookies'
const Support = () => {
    const [Open, setOpen] = useState(0);
    const { getAllTickets } = useTicketsHelper();

    const [ticketOpen, setTicketOpen] = useState(false);
    const [Change, setChange] = useState([]);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [DetailsData, setDetailsData] = useState(false);
    const [DetailsOpen, setDetailsOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        getAllTickets(setData, setLoading)
    }, [Change])

    const onOpenTicket = () => {
        const token = getToken();
        if (token) {
            setTicketOpen(s => !s);
        } else {
            toast.error('Please login to open a ticket!');
            navigate('/login?redirect=/support');
        }
    }


    return (
        <>
            <Layout>
                <Banner
                    fullscreen={false}
                    img={'/Assets/Images/lines.png'}
                    data={
                        <>
                            <h2 className='font-extrabold md:text-[3rem] text-[2.2rem] leading-[1.2]'>
                                Support Centre
                            </h2>
                            <p className='text-[#4F4D52] text-sm'>
                                Unsure on how to use the VPN app?
                                <br className='md:block hidden' />
                                Search for your answer in our support center or in the categories below.
                            </p>
                        </>
                    }
                />
                <section>
                    <div className='container'>
                        <div className='flex flex-col gap-[3rem] mb-[3rem]'>

                            <div className='flex justify-end items-center'>
                                <Form.Button onClick={onOpenTicket}>Open Ticket</Form.Button>
                            </div>

                            <div>
                                <div className='border border-[#1D1B21] rounded-xl px-5 py-3 flex items-center gap-3'>
                                    <Svgs.Search />
                                    <input type="text" className='w-full border-none outline-none bg-transparent text-sm' placeholder='Have a question? Search here...' />
                                </div>
                            </div>
                            <div>
                                <div className='flex flex-col gap-5'>
                                    {
                                        loading ?
                                            [1, 2, 3, 5, 6, 4].map(ele => <div key={ele} className='h-[3.5rem] w-full rounded-lg bg-gray-500 animate-pulse'>
                                            </div>) :
                                            data.map((item, i) => {
                                                return <div key={i} className='border rounded-xl px-5 py-2.5'>
                                                    <div onClick={() => setOpen(i === Open ? -1 : i)} className='flex items-center gap-4 justify-between cursor-pointer'>
                                                        <div className='flex items-center gap-3'>
                                                            <div>
                                                                <Svgs.User />
                                                            </div>
                                                            Account
                                                        </div>
                                                        <div className='flex items-center text-sm gap-3'>
                                                            {/* <p className='text-[#4F4D52]'>5 Topics</p> */}
                                                            {i === Open ? <Svgs.Up /> : <Svgs.Down />}
                                                            
                                                        </div>
                                                    </div>
                                                    {
                                                        Open === i && <div className='mt-3'>
                                                            <h1 className='text-white'>{item?.subject}</h1>
                                                            <p className='text-[#4F4D52] text-sm'>{item?.description}</p>
                                                            <p className='text-[#FACA7F] text-sm cursor-pointer mt-2 hover:underline w-fit' onClick={() => {
                                                                setDetailsData(item);
                                                                setDetailsOpen(s => !s)
                                                            }}>View Details</p>
                                                        </div>
                                                    }
                                                </div>
                                            })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
            <AddTicket open={ticketOpen} close={setTicketOpen} setChange={setChange} />
            {
                DetailsOpen && <DetailsPopup open={DetailsOpen} close={setDetailsOpen} data={DetailsData} />
            }

        </>
    )
}

export default Support