import React from "react";


import { useNavigate } from "react-router-dom";

import FlexRow from "../Layout/FlexRow";


const PricingCard = ({
    name,
    price,
    duration,
    title,
    features = [],
    currency = "$",
    main = false,
    btnTitle = "Order Now",
    plan = null,
    onCardClick = null
}) => {
    const navigate = useNavigate();

    const handleOnClick = () => {
        if (onCardClick) {
            onCardClick(plan);
        } else {
            navigate('/pricing', { state: plan })
        }
    }


    if (main) {
        return (
            <div
                className="bg-[#FACA7F] text-black flex flex-col items-center justify-center p-10"
                style={{ width: "360px", height: "600px" }}
            >
                <h2 className="text-xl font-bold mb-2">{name}</h2>
                <img
                    alt="alt"
                    src={`${process.env.PUBLIC_URL}/Assets/Images/app-icon.png`}
                    className="h-[3rem] object-contain "
                />
                <h3 className="text-xl mb-2">{currency + price}</h3>
                <h5 className="text-xl mb-2">{duration}</h5>

                <div className="border-b my-2 border-black text-[#FACA7F]"> </div>
                <h2 className="text-xl font-bold mb-2">{title}</h2>
                {features.map((feature, i) => {
                    return <p key={i} className="text-center">{feature}</p>;
                })}

                <button
                    className="bg-[#2e2a2d] text-white mt-5 rounded-full order border-black btn-animation"
                    style={{ width: "70%", height: "2.5rem", }}
                    onClick={() => handleOnClick(plan)}
                >
                    <FlexRow className="text-lg justify-center">
                        <h1>{btnTitle}</h1>
                    </FlexRow>
                </button>
            </div>
        );
    } else {
        return (
            <div
                className="bg-white text-black flex flex-col items-center justify-center  p-10"
                style={{ width: "350px", height: "500px" }}
            >
                <h2 className="text-xl font-bold mb-2">{name}</h2>
                <img
                    alt=""
                    src={`${process.env.PUBLIC_URL}/Assets/Images/app-icon.png`}
                    className="h-[3rem] object-contain "
                />
                <h3 className="text-xl mb-2">{currency + price}</h3>
                <h5 className="text-xl mb-2">{duration}</h5>

                <div className="border-b my-2 border-black" style={{ width: "100%" }}> </div>


                <h2 className="text-xl font-bold mb-2">{title}</h2>
                {features.map((feature, index) => {
                    return <p key={index} className="text-center">{feature}</p>;
                })}


                <button
                    className="rounded-full mt-5 h-[2.5rem] bg-[#faca7f] btn-animation"
                    style={{ width: "70%" }}
                    onClick={() => handleOnClick(plan)}
                >
                    <FlexRow className="text-lg justify-center">
                        <h1>{btnTitle}</h1>

                    </FlexRow>
                </button>
            </div>
        );
    }
};

export default PricingCard;
