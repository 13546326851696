import React from 'react'
import Banner from '../../Components/Common/Banner'
import Form from '../../Components/Form'
import FlexRow from '../../Components/Layout/FlexRow'
import Svgs from '../../Svgs'
import Layout from '../../Components/Layout'
import FlexCol from '../../Components/Layout/FlexCol'
import { useNavigate } from 'react-router-dom'

const Download = () => {
    const navigate = useNavigate();
    return (
        <Layout>
            <Banner
                img={'/Assets/Images/download.png'}
                data={
                    <>
                        <h2 className='font-extrabold md:text-[3rem] text-[2.2rem] leading-[1.2]'>
                            Friendly and Easy-to-Use
                            <br className='md:block hidden' />
                            Interface for Windows
                        </h2>
                        <p className='text-[#fff] text-sm'>
                            The Octo Shield Vpn app for Windows is the one of the greatest solution
                            <br className='md:block hidden' />
                            for secure and private internet browsing. It is user-friendly and equipped
                            with top-rated encryption.
                            <br className='md:block hidden' />
                            <br className='md:block hidden' />
                            Download and install the Windows app now and log in with your account.
                            <br className='md:block hidden' />
                            If you don't have an account yet, <span className='text-[#FACA7F] cursor-pointer' onClick={()=>{navigate("/register")}}> sign up here.</span>
                        </p>
                        <div>
                            <Form.Button>
                                <FlexRow className='text-sm'>
                                    <Svgs.Download />
                                    <h1>Download</h1>
                                </FlexRow>
                            </Form.Button>
                        </div>
                    </>
                }
            />
            <section>
                <div className='mt-[3rem] mb-[5rem]'>
                    <div>
                        <h1 className='text-center text-[1.5rem]'>Choose your device system</h1>
                    </div>
                    <div className="flex items-center lg:gap-[4rem] gap-[2rem] justify-center flex-wrap py-[3rem]">
                        <div className='flex items-center gap-4 rounded-xl px-[2.5rem] py-[1.3rem] download-hover'>
                            <Svgs.Windows size={'40'} fill={'#fff'} />
                            <p>Windows</p>
                        </div>
                        <div className='flex items-center gap-4 rounded-xl px-[2.5rem] py-[1.3rem] download-hover'>
                            <Svgs.Andriod size={'40'} fill={'#fff'} />
                            <p>Andriod</p>
                        </div>
                        <div className='flex items-center gap-4 rounded-xl px-[2.5rem] py-[1.3rem] download-hover'>
                            <Svgs.Apple size={'40'} fill={'#fff'} />
                            <p>Apple</p>
                        </div>
                        <div className='flex items-center gap-4 rounded-xl px-[2.5rem] py-[1.3rem] download-hover'>
                            <Svgs.IOS size={'40'} fill={'#fff'} />
                            <p>IOS</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className='mb-[3rem]'>
                <div>
                    <h1 className='text-center text-[1.5rem]'>How to use the app on Windows</h1>
                </div>
                <div className='mt-[3rem]'>
                    <div className='lg:grid flex flex-col grid-cols-3 gap-[2rem] w-[85%] mx-auto'>
                        <div>
                            <FlexCol className={'gap-6'}>
                                <div className='bg-[#131118] text-[#FACA7F] h-[3rem] w-[3rem] rounded-full flex items-center justify-center font-semibold'>1</div>
                                <div>
                                    <h1 className='text-[1.2rem]'>Install the app and log in</h1>
                                </div>
                                <div>
                                    <h1 className='text-[#4F4D52] text-sm'>Once you've installed the app, open it and sign in
                                        with your account. If you already have an account
                                        with us, you can access the free plan by logging
                                        in with the same credentials.</h1>
                                </div>
                            </FlexCol>
                        </div>
                        <div>
                            <FlexCol className={'gap-6'}>
                                <div className='bg-[#131118] text-[#FACA7F] h-[3rem] w-[3rem] rounded-full flex items-center justify-center font-semibold'>2</div>
                                <div>
                                    <h1 className='text-[1.2rem]'>Connect to desired server</h1>
                                </div>
                                <div>
                                    <h1 className='text-[#4F4D52] text-sm'>
                                        Search for your desired server location or select
                                        one of the categories (streaming, high speed or
                                        ad-blocker) based on your needs.
                                    </h1>
                                </div>
                            </FlexCol>
                        </div>
                        <div>
                            <FlexCol className={'gap-6'}>
                                <div className='bg-[#131118] text-[#FACA7F] h-[3rem] w-[3rem] rounded-full flex items-center justify-center font-semibold'>3</div>
                                <div>
                                    <h1 className='text-[1.2rem]'>You're set and ready to go!</h1>
                                </div>
                                <div>
                                    <h1 className='text-[#4F4D52] text-sm'>
                                        Now you can experience safe and private internet
                                        access. All of your data traveling between your
                                        device and the VPN server is encrypted to protect
                                        your privacy.
                                    </h1>
                                </div>
                            </FlexCol>
                        </div>
                    </div>
                </div>
                <div className='text-sm text-center mt-[5rem]'>
                    <h1>If you are looking for step-by-step installation instructions, visit <span className='text-[#FACA7F]'>Support Centre.</span></h1>
                </div>
            </section>

        </Layout>
    )
}

export default Download