export const HeaderData = [
    {
        "name": "Home",
        "url": "/"
    },
    {
        "name": "Pricing",
        "url": "/pricing"
    },
    {
        "name": "Support",
        "url": "/support"
    },
    {
        "name": "Download",
        "url": "/download"
    },
    {
        "name": "Servers",
        "url": "/servers"
    },
]

export const OPTData = [
    {
        title: "High-Speed, Reliable Connections",
        info: "Octo Shield VPN provides fast and stable connections, ensuring a seamless browsing experience without any buffering or lag. You can enjoy lightning-fast speeds while streaming, downloading, and browsing online.",
        icon: "speedometer.png"
    },
    {
        title: "Military-Grade Encryption for Ultimate Security",
        info: "Octo Shield VPN uses advanced encryption technologies to secure your internet connection and protect your personal information. With military-grade encryption, you can be confident that your data is safe from cyber threats and hacking attempts.        ",
        icon: "security.png"
    },
    {
        title: "Global Coverage with Multiple Server Locations",
        // info: "Octo Shield VPN provides a global server network for flexible server location options, ensuring access to restricted content while traveling. The user-friendly VPN software offers easy protection with a simple and intuitive design, all with just a few clicks",
        info: "The user-friendly VPN software offers easy protection with a simple and intuitive design, all with just a few clicks",
        icon: "worldwide.png"
    },
    {
        title: "High-Speed, Reliable Connections",
        info: "Octo Shield VPN provides 24/7 customer support, so you can get help whenever you need it. Whether you need assistance with setup, troubleshooting, or anything else, Octo Shield VPN's customer support team is always available to help.        ",
        icon: "customer-service.png"
    },
    {
        title: "7-Day Risk-Free Money-Back Guarantee",
        info: "Octo Shield VPN offers a 7-day money-back guarantee, so you can try the VPN service risk-free. If you're not completely satisfied with Octo Shield VPN, simply request a refund, and you'll get your money back.",
        icon: "money-back.png"
    },
    {
        title: "No-Log Policy for Complete Privacy",
        info: "Octo Shield VPN follows a strict no-log policy, which means that no data or information about your online activities is recorded or stored. With Octo Shield VPN, you can be confident that your privacy is protected.",
        icon: "shield-with-lock.png"
    },
]

export const FooterData = [
    {
        title: "Pages",
        list: [
            {
                name: "Home",
                url: "/"
            },
            {
                name: "Pricing",
                url: "/pricing"
            },
            {
                name: "Support",
                url: "/support"
            },
            {
                name: "Servers",
                url: "/servers"
            },
            {
                name: "F.A.Q.",
                url: "/faq"
            },
        ]
    },
    {
        title: "Legal",
        list: [
            {
                name: "Shipping Policy",
                url: "/shipping-policy"
            },
            {
                name: "Terms of Service",
                url: "/terms"
            },
            {
                name: "Privacy Policy",
                url: "/privacy-policy"
            },
        ]
    },
    {
        title: "Contact",
        list: [
            {
                name: "Live Chat",
                url: "/"
            },
            {
                name: "WhatsApp",
                url: "/"
            },
            {
                name: "Email",
                url: "/"
            },
        ]
    },
]

export const Packages = [
    {
        name: "1 month",
        price: "5.99"
    },
    {
        name: "3 month",
        price: "12.99",
        discount: "28% off"
    },
    {
        name: "6 month",
        price: "19.99",
        discount: "44% off"
    },
    {
        name: "1 year",
        price: "33.99",
        discount: "53% off"
    },
    {
        name: "2 years",
        price: "49.99",
        discount: "65% off"
    },
    {
        name: "lifetime",
        price: "189.99"
    },
]


export const questions = [
    {
        question: "What is a VPN, and why do I need one?",
        answer: "A VPN (Virtual Private Network) encrypts your online traffic, ensuring privacy and security. It's essential for protecting your data from hackers and accessing restricted content.",

    }, {
        question: "How does OCTO Shield VPN work?",
        answer: "OCTO Shield VPN creates an encrypted tunnel for your internet traffic, safeguarding your data. Additionally, our VPN routers extend this protection to all devices connected to your home network.",
    }, {
        question: "Can I use OCTO Shield VPN on multiple devices?",
        answer: "Yes, you can secure numerous devices using one subscription, including smartphones, laptops, tablets, and even devices connected to our VPN router.",
    }, {
        question: "How do I set up OCTO Shield VPN?",
        answer: "Setting up OCTO Shield VPN is easy. Download our app for individual devices and follow the setup process.",
    }, {
        question: "Can I use the 2-day free trial for both the VPN service and router?",
        answer: "Yes, the 2-day free trial covers only our VPN service and not the router. Explore our features and benefits risk-free during this trial period.",
    }, {
        question: "Will using OCTO Shield VPN slow down my internet connection?",
        answer: "While all VPNs may slightly reduce speed due to encryption, OCTO Shield VPN and our VPN router are designed for minimal impact, providing optimal browsing and streaming experiences.",
    }

];
