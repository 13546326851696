import { toast } from "react-toastify";
import { AllTicket, CreateTicket, GetTicket, ReplyTicket, baseUrl } from "../Constants";
import { getToken } from "../Cookies";

function useTicketsHelper() {

    const getAllTickets = (setData, setLoading) => {
        setLoading(true)
        var requestOptions = {
            method: 'GET',
            headers: {
                Accept: "application/json",
                Authorization: getToken()
            },
        };
        fetch(baseUrl + AllTicket, requestOptions)
            .then(res => res.json())
            .then(res => {
                setLoading(false);
                if (res.status) {
                    setData(res?.data?.tickets)
                }
            })
    }
    const getTicket = (data, setData, setLoading) => {
        setLoading(true)
        var requestOptions = {
            method: 'GET',
            headers: {
                Accept: "application/json",
                Authorization: getToken()
            },
        };
        fetch(baseUrl + GetTicket + data?.ticket_id, requestOptions)
            .then(res => res.json())
            .then(res => {
                setLoading(false);
                if (res.status) {
                    setData(res?.data?.ticket)
                }
            })
    }

    const createTicket = async (values, setLoading) => {
        setLoading(true)
        var formdata = new FormData();
        formdata.append("subject", values.subject);
        formdata.append("description", values.description);
        var requestOptions = {
            method: 'POST',
            headers: {
                Accept: "application/json",
                Authorization: getToken()
            },
            body: formdata,
        };
        return await fetch(baseUrl + CreateTicket, requestOptions)
            .then(res => res.json())
            .then(res => {
                if (res.status) {
                    return res
                }else{
                    toast.error(res?.data?.message);
                }
            })
    }

    const replyTicket = async (values, setLoadingReply) => {
        setLoadingReply(true)
        var formdata = new FormData();
        formdata.append("message", values.reply);
        var requestOptions = {
            method: 'POST',
            headers: {
                Accept: "application/json",
                Authorization: getToken()
            },
            body: formdata,
        };
        return await fetch(baseUrl + ReplyTicket + values.ticket_id, requestOptions)
            .then(res => res.json())
            .then(res => {
                setLoadingReply(false)
                if (res.status) {
                    return res
                } else {
                    toast.warning(res?.data?.message);
                }
            })
    }

    return {
        createTicket,
        getAllTickets,
        getTicket,
        replyTicket
    }
}

export default useTicketsHelper