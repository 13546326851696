import React, { useEffect, useState } from "react";
import Popup from "../../Components/Common/Popup";
import useTicketsHelper from "../../Actions/Tickets";
import FlexCol from "../../Components/Layout/FlexCol";
import Form from "../../Components/Form";
import FlexRow from "../../Components/Layout/FlexRow";
import useValidations from "../../Components/Form/Validations";
import { text } from "../../Components/Form/Validations/Schema";

const DetailsPopup = ({ open, close, data }) => {
    const { getTicket, replyTicket } = useTicketsHelper();
    const [Data, setData] = useState({});
    const [Loading, setLoading] = useState(false);
    const [LoadingReply, setLoadingReply] = useState(false);

    useEffect(() => {
        getTicket(data, setData, setLoading);
    }, [data]);

    const onSubmit = (values) => {
        replyTicket({ ...values, ...data }, setLoadingReply).then(res => {
            getTicket(data, setData, setLoading);
            form.resetForm();
        })
    }
    const initialValues = { reply: "" }
    const validationSchema = { reply: text }
    const form = useValidations({ onSubmit, validationSchema, initialValues });

    return (
        <Popup open={open} close={close} heading={"Ticket Details"} parent_class={'pb-0'}>

            {
                Loading ? <FlexCol>
                    <div className="animate-pulse bg-gray-500 h-[1.5rem] rounded-md"></div>
                    <div className="animate-pulse bg-gray-500 h-[1.5rem] rounded-md"></div>
                    <div className="animate-pulse bg-gray-500 h-[1.5rem] rounded-md"></div>
                    <div className="animate-pulse bg-gray-500 h-[1.5rem] rounded-md"></div>
                    <FlexRow className={'mt-3'}>
                        <div className="flex-1 animate-pulse bg-gray-500 h-[2rem] rounded-md"></div>
                        <div className="animate-pulse bg-gray-500 h-[2rem] rounded-md w-[4rem]"></div>
                    </FlexRow>
                </FlexCol> : Object.keys(Data).length > 0 && (
                    <>
                        <FlexCol>
                            <p className="text-sm">
                                <span className="text-[#faca7f]">Subject: </span>
                                <span>{Data?.subject}</span>
                            </p>
                            <p className="text-sm">
                                <span className="text-[#faca7f]">Ticket ID: </span>
                                <span>{Data?.ticket_id}</span>
                            </p>
                            <p className="text-sm">
                                <span className="text-[#faca7f]">Description: </span>
                                <span>{Data?.description}</span>
                            </p>
                            <p className="text-sm">
                                <span className="text-[#faca7f] ">Status: </span>
                                <span className="capitalize">{Data?.status}</span>
                            </p>
                        </FlexCol>
                        <FlexCol className="my-4">
                            {Data?.replies.length > 0 && Data?.replies.map((ele,i) => (
                                <div key={i} className="border rounded-xl px-5 py-2.5">
                                    <div className="flex items-center gap-4 justify-between">
                                        <div className="flex items-center text-sm gap-3">
                                            <p className="text-[#4F4D52]">{new Date(ele?.created_at).toLocaleString('en-US', { month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true })}</p>
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        <h1 className="text-white">{ele?.message}</h1>
                                        {
                                            ele.from_admin && <p className="text-[#FACA7F] text-sm mt-2">
                                                from Admin
                                            </p>
                                        }
                                    </div>
                                </div>
                            ))}
                        </FlexCol>
                        <div className="sticky bottom-0 py-4 bg-black">
                            {Data?.is_resolved ?
                            <FlexRow className={"text-center"}>
                                <p className="text-text-red-400	">Ticket Has Been Resolved</p>
                            </FlexRow>:
                            <form onSubmit={form.handleSubmit}>
                                
                                <FlexRow>
                                    <div className="flex-1">
                                        <Form.Input disabled={Data?.status === "pending"} form={form} name={'reply'} placeholder={Data?.status === "pending" ? "Your ticket is pending please wait their for reply" : "Ticket Reply"} />
                                    </div>
                                    {
                                        Data?.status !== "pending" &&
                                        <Form.Button loading={LoadingReply} type={'submit'}>Reply</Form.Button>
                                    }
                                </FlexRow>
                            </form>
                            
                        }
                        </div>
                    </>
                )
            }
        </Popup>
    );
};

export default DetailsPopup;
